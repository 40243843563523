import Axios from 'axios';
import Token from './Token';

class Api {
  constructor() {
    this.instance = Axios.create({
      baseURL: `${window.location.protocol}//${window.location.hostname}`,
    });

    this.instance.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
        return Promise.reject(error);

    });
  }

  getWithoutAuth(url, config = {}) {
    return this.instance.get(url, config);
  }

  postWithoutAuth(url, data, config = {}) {
    return this.instance.post(url, data, config);
  }

  get(url, config = {}) {
    const extendedConfig = Api.addAuthenticationHeader(config);
    return this.instance.get(url, extendedConfig);
  }

  post(url, data, config = {}) {
    const extendedConfig = Api.addAuthenticationHeader(config);
    return this.instance.post(url, data, extendedConfig);
  }

  put(url, data, config = {}) {
    const extendedConfig = Api.addAuthenticationHeader(config);
    return this.instance.put(url, data, extendedConfig);
  }

  delete(url, config = {}) {
    const extendedConfig = Api.addAuthenticationHeader(config);
    return this.instance.delete(url, extendedConfig);
  }

  static addAuthenticationHeader(config) {
    const token = Token.get();
    const headers = { Authorization: `Bearer ${token}`, ...config.headers };
    const extendedConfig = { ...config, headers };
    return extendedConfig;
  }
}

export default new Api();
