// SomeChildComponent.js
import React, { useState, useEffect, useCallback } from 'react'
import Axios from 'axios';
import styled from 'styled-components';
import { register } from './serviceWorker';
import useOnlineStatus from '@rehooks/online-status';


const NewVersionWrapper = styled.div`
    text-align: center;
    padding: 5px;
    line-height: 1;
    font-size: 14px;
`

const OfflineWrapper = styled.div`
    font-size: 16px;
    padding: 5px 10px;
    text-align: center;
    background: #FF8B86;
    padding: 5px 10px;
    border: 1px solid #FF3F34;    color: #FFF;
    border-radius: 10px;
    width: 100%;
`

const NewVersionButton = styled.button`
    font-size: 16px;
    padding: 5px 10px;
    text-align: center;
    background: #05C46B;
    color: #FFF;
    border-radius: 10px;
    width: 100%;
    border: none;
    box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
`

function ServiceWorkerWrapper({ children }) {

    const [showReload, setShowReload] = useState(false);
    const onlineStatus = useOnlineStatus();

    const onSWUpdate = (registration) => {
        if(registration.waiting){
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload(true);
    };
    function fetchVersion() {
        Axios.get('/doors/version.json', {
            headers: {
                'Cache-Control': 'no-cache',
            },
        }).then((response) => {
            if (response.data.version !== process.env.REACT_APP_VERSION) {
                setShowReload(true);
            } else {
                setTimeout(() => {
                    fetchVersion();
                }, 100000);
            }
        });


    }


    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            fetchVersion();
        }
        register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = useCallback(() => {
        window.location.reload(true);
    });


    if (showReload) {
        return (<><NewVersionWrapper>
            <NewVersionButton onClick={reloadPage}>Click & reload for new version</NewVersionButton>
        </NewVersionWrapper>
            {children}
        </>
        );
    }

    return (
        <>
        {!onlineStatus && (<NewVersionWrapper>
            <OfflineWrapper>
            You appear to be offline
            </OfflineWrapper>
        </NewVersionWrapper>)}
        {children}
        </>
    )
}

export default ServiceWorkerWrapper;