const tokenStorage = "dwi-token";

export default class Token {
  static get() {
    return localStorage.getItem(tokenStorage);
  }

  static set(token) {
    localStorage.setItem(tokenStorage, token);
  }

  static remove() {
    localStorage.removeItem(tokenStorage);
  }

  static getData(data) {
    const token = Token.get();
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const json = JSON.parse(window.atob(base64));
    return json[data];
  }

}

