import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import Api from './Api';
import Token from './Token';
import { store } from './store';
import Wrapper from './Wrapper';

const LoginTitle = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: bold;
`


const Error = styled.div`
    text-align: center;
    font-size: 20px;
    background: #FF8B86;
    padding: 5px 10px;
    border: 1px solid #FF3F34;
    color: #000;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`

const Label = styled.label`
    font-size: 14px;
    padding: 5px;
    display: block;
`;

const Input = styled.input`
    font-size: 14px;
    padding: 9px 10px;
    border: 2px solid #AAA;
    border-radius: 10px;
    width: 100%;
`;

const Button = styled.button`
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    background: #05C46B;
    color: #FFF;
    border-radius: 10px;
    width: 100%;
    border: none;
`;

const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
`

function Authenticate({ domain }) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { dispatch } = useContext(store);

    const authenticateUser = useCallback((e) => {
        e.preventDefault();

        if (username.length < 2 && password.length < 2) {
            setError(true);
            return;
        }

        setLoading(true);

        Api.postWithoutAuth('/auth/doors/login', {
            username,
            password,
            url: window.location.host,
        }).then((response) => {
            Token.set(response.data.token);
            dispatch({ type: 'login' });
            setError(false);
            setLoading(false);
        }).catch(() => {
            setError(true);
            setLoading(false);
        })
    }, [username, password])

    return (
        <Wrapper domain={domain}>
            <form onSubmit={authenticateUser}>
                <LoginTitle>
                    Login
                </LoginTitle>
                {error && (
                    <Error>
                        Login incorrect!
                    </Error>
                )}
                <InputWrapper>
                    <Label>Username</Label>
                    <Input type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                </InputWrapper>
                <InputWrapper>
                    <Label>Password</Label>
                    <Input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                </InputWrapper>
                <div>
                    <Button type="submit" disabled={loading} >{loading ? 'Logging in...' : 'Login'}</Button>
                </div>
            </form>

        </Wrapper>
    );
}

export default Authenticate;
