import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import styled from 'styled-components';
import useOnlineStatus from '@rehooks/online-status';

import { motion, useAnimation } from "framer-motion"
import Api from './Api';
import Wrapper from './Wrapper';
import { store } from './store';

const RecheckLocationButton = styled.button`
    font-size: 16px;
    margin-top: 5px;
    padding: 5px 10px;
    text-align: center;
    background: #05C46B;
    color: #FFF;
    border-radius: 10px;
    width: 100%;
    border: none;
    box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
`


const DoorButton = styled(motion.button)`
  --webkit-appearance: none;
  background: #FFF;
  border: 5px solid #000;
  color: #000;
  border-radius: 100%;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  position: relative;
  -webkit-box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
  box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
   -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */

   :focus{
     outline: 0;
   }         
`

const DoorInner = styled.div`
  position: relative;
  z-index: 5;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
`

const DoorTitle = styled.div`
    font-size: 30px;
    font-weight: bold;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
`
const DoorOpen = styled.div`
    font-size: 15px;
     -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
`


const Loading = styled.div`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`

const getDoorText = (props) => {

  if (props.geolocationOff) {
    return 'Not in range';
  }

  switch (props.status) {
    case 'open':
      return 'open';
    case 'fail':
      return 'failed';
    case 'opening':
      return 'opening...';
    default:
      return 'hold to open';
  }

}


const getDoorBackground = (props) => {

  switch (props.status) {
    case 'open':
      return '#0BE881';
    case 'fail':
      return '#b32d00';
    case 'opening':
      return '#0099cc';
    default:
      return '#e0f7ff';
  }

}

const DoorBackground = styled(motion.div)`
  width: 190px;
  height: 190px;
  background: ${props => getDoorBackground(props)};
  transition: 0.2s background ease-in-out;
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  border-radius: 100%;
`

const DoorWrapper = styled.div`
  margin: 30px auto;
  text-align: center;
`

const Error = styled.div`
    text-align: center;
    font-size: 14px;
    background: #FF8B86;
    padding: 5px 10px;
    border: 1px solid #FF3F34;
    color: #000;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Message = styled.div`
    text-align: center;
    font-size: 14px;
    background: #DDD;
    padding: 5px 10px;
    border: 1px solid #AAA;
    color: #000;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`

function arePointsNear(checkPoint, centerPoint, meter) {
  const km = meter / 1000;
  const ky = 40000 / 360;
  const kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
  const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
}

async function getPosition() {
  const location = new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((pos) => {
      resolve(pos)
    }, (err) => {
      reject(err);
    }, {
      maximumAge: 0
    });
  });

  return location;
}

function Door({ door, geolocation }) {
  const [status, setStatus] = useState(null);
  const [cirlcleStatus, setCircleStatus] = useState(null);
  const tapping = useRef(false);
  const opening = useRef(false);
  const tappingInterval = useRef(null);
  const controls = useAnimation();
  const controlsCircle = useAnimation();

  let geolocationOff = false;
  // if (door.lat !== null && door.long !== null) {
  //   if (!geolocation) {
  //     geolocationOff = true;
  //   } else {
  //     const checkPoint = {
  //       lat: geolocation.latitude,
  //       lng: geolocation.longitude,
  //     };

  //     const centerPoint = {
  //       lat: door.lat,
  //       lng: door.long,
  //     }
  //     geolocationOff = !arePointsNear(checkPoint, centerPoint, door.radius !== null ? door.radius : 500);
  //   }
  // }

  const resetButton = () => {
    controls.start({
      scale: 0,
      transition: {
        duration: 0.5,
      }
    });
    controlsCircle.start({
      strokeDashoffset: 613,
      transition: {
        duration: 0,
      }
    });
    setStatus(null);
    setCircleStatus(null);
  }

  const openDoor = useCallback(() => {
    if (door.disabled || geolocationOff) {
      return;
    }

    setStatus('opening');
    opening.current = true;

    let canOpen = true;
    // if (door.lat !== null && door.long !== null) {
    //   const checkPoint = {
    //     lat: geolocation.latitude,
    //     lng: geolocation.longitude,
    //   };

    //   const centerPoint = {
    //     lat: door.lat,
    //     lng: door.long,
    //   }
    //   canOpen = arePointsNear(checkPoint, centerPoint, door.radius !== null ? door.radius : 500);
    // }

    if (canOpen) {
      Api.post(`/doors/open/${door.id}`)
        .then(() => {
          setCircleStatus('open');
          setTimeout(() => {
            setStatus('open');
          }, 500);
          setTimeout(() => {
            opening.current = false;
            resetButton();
          }, 1500);
          controlsCircle.start({
            strokeDashoffset: 0,
            transition: {
              duration: 0.5,
            }
          });
        }).catch(() => {
          setCircleStatus('fail');
          setTimeout(() => {
            setStatus('fail');
          }, 500);
          setTimeout(() => {
            opening.current = false;
            resetButton();
          }, 1500);

          controlsCircle.start({
            strokeDashoffset: 0,
            transition: {
              duration: 0.5,
            }
          });
        });
    } else {
      setCircleStatus('fail');
      setTimeout(() => {
        setStatus('fail');
      }, 500);
      setTimeout(() => {
        opening.current = false;
        resetButton();
      }, 1500);
    }

  }, [door, geolocationOff]);


  const tappingDoor = useCallback((e) => {
    e.preventDefault();
    if (door.disabled || geolocationOff) {
      return;
    }
    if (tappingInterval.current !== null) {
      clearTimeout(tappingInterval.current);
    }
    tapping.current = true;


    controls.start({
      scale: 1,
      transition: {
        duration: 2,
      }
    });


    const timeout = setTimeout(() => {
      if (tapping.current) {
        openDoor();
      }
      tapping.current = false;
    }, 2000);

    tappingInterval.current = timeout;

  }, [door, geolocationOff]);

  const cancelTapping = () => {
    tapping.current = false;

    if (opening.current === false) {
      resetButton();
    }


    clearTimeout(tappingInterval.current);
  };



  return (
    <DoorWrapper>
      <DoorButton
        disabled={door.disabled || geolocationOff}
        whileTap={{ scale: 1.1 }}
        onTouchStart={tappingDoor}
        onMouseDown={tappingDoor}
        onMouseUp={cancelTapping}
        onTouchEnd={cancelTapping}
        onContextMenu={e => e.preventDefault()}
      >
        <motion.svg
          style={{ position: 'absolute', left: '-5px', top: '-5px' }}
          height="200" width="200">
          <motion.circle
            animate={controlsCircle}
            fill='none'
            strokeDasharray="613"
            strokeDashoffset="613"
            stroke={getDoorBackground({ status: cirlcleStatus })}
            strokeWidth='6'
            x="0"
            y="0"
            cx='100'
            cy='100'
            r='97.5' />
        </motion.svg>
        <DoorInner>
          <DoorTitle>
            {door.title}
          </DoorTitle>
          <DoorOpen>
            {getDoorText({ status, geolocationOff })}
          </DoorOpen>
        </DoorInner>

        <DoorBackground
          initial={{ scale: 0 }}
          animate={controls}
          status={status}
        />
      </DoorButton>
    </DoorWrapper>
  )
}

function LocationError({ error, recheckLocation }) {

  if (error.code === 1) {
    return (<Error>
      You either denied the request for location services
      or your browser blocks location services.
      Restart/reload the app or check your privacy settings.
    </Error>);
  }

  return ([<Error>
    {!error.message || error.message === '' ? 'Location error' : error.message}
  </Error>
  ])
}


function Doors({ domain }) {
  const [geolocation, setGeolocation] = useState(null);
  const [geolocationError, setGeolocationError] = useState(null);
  const [doors, setDoors] = useState([]);
  const [loading, setLoading] = useState(true);
  const posInterval = useRef(null);
  const { dispatch } = useContext(store);

  useEffect(() => {
    Api.get('/doors')
      .then((r) => {
        setDoors(r.data.doors);
        setLoading(false);
      }).catch(() => {
        dispatch({ type: 'reload' });
      });
  }, []);

  // const setPosition = () => {
  //   getPosition()
  //     .then((pos) => {
  //       setGeolocation(pos);
  //       setGeolocationError(null);
  //     }).catch((err) => {
  //       setGeolocation(null);
  //       setGeolocationError(err);
  //     })
  // }

  // const recheckLocation = () => {
  //   setPosition();
  // }

  const reloadHard = () => {
    window.location.reload(true);
  }

  // useEffect(() => {
  //   setPosition();
  //   posInterval.current = setInterval(() => {
  //     setPosition()
  //   }, 1 * 60 * 1000);

  //   return () => {
  //     clearInterval(posInterval.current);
  //   }
  // }, [posInterval]);

  if (loading) {
    return <Loading>
      Loading...
    </Loading>
  }

  let geoKey = 'null';
  // if(geolocation && !geolocationError ){
  //   geoKey = `${geolocation.coords.latitude}_${geolocation.coords.longitude}`
  // }

  return (
    <Wrapper domain={domain}>
      {/* {!geolocation && !geolocationError && (
        <Message>
          Waiting for location...
        </Message>
      )} */}

      {/* {geolocationError && (<LocationError error={geolocationError} recheckLocation={recheckLocation} />)} */}
      {/* <RecheckLocationButton onClick={geolocationError ?  reloadHard : recheckLocation}>Reload location</RecheckLocationButton> */}

      {
        doors.map((door) => (
          <Door key={`${door.id}_${geoKey}`} door={door} geolocation={geolocation && !geolocationError ? geolocation.coords : null} />
        ))
      }
    </Wrapper >
  );
}

export default Doors;
