import React from 'react';
import styled from 'styled-components';


const Header = styled.header`
   background: #000;
   -webkit-box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
  box-shadow: -1px 10px 46px -21px rgba(0,0,0,0.75);
  margin-bottom: 20px;
`;

const HeaderInner = styled.div`
    margin: 0 auto;
    max-width: 320px;
    padding: 10px 0;
    text-align:center;
`;

const Main = styled.main`
    margin: 0 auto;
    max-width: 320px;
`

const Logo = styled.img`
   height: 30px;
   width: auto;
`

function Wrapper({ children, domain }) {
    return (
        <div>
            <Header>
                <HeaderInner>
                    <Logo src={`${process.env.PUBLIC_URL}/${domain.logo || 'logo.svg'}`}/>
                </HeaderInner>
            </Header>
            <Main>
                {children}
            </Main>
        </div>
    );
}

export default Wrapper;
